import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router.js'

createApp(App, {windowLocationSearch: window.location.search})
  .use(Quasar, quasarUserOptions)
  .use(router)
  .mount('#app')
