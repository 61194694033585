<template>
    <div class="swagger absolute-full" id="swagger"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

export default {
    name: "SwaggerUI",
    mounted() {
        SwaggerUI({
            url: '/oas30.json',
            dom_id: '#swagger'
        })
    }
}
</script>
