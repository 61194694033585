<template>
    <div id="app">
        <UserLoginPage v-if="!isLoggedIn" v-on:LoginSuccess="onLoginStateChanged"/>
        <MainPage v-if="isLoggedIn" v-on:LogoutSuccess="onLoginStateChanged" :apiKey="apiKey"/>
    </div>
</template>

<script setup>
import UserLoginPage from "./pages/UserLoginPage.vue";
import MainPage from "./pages/MainPage.vue";
import { ref, defineProps } from 'vue';

const props = defineProps({
    windowLocationSearch: String,
});

const apiKey = new URLSearchParams(props.windowLocationSearch).get('api-key');
if (apiKey) {
    localStorage.setItem('ApiKey', apiKey);
}

const isLoggedIn = ref(false);
isLoggedIn.value = localStorage.getItem('ApiKey');

function onLoginStateChanged() {
    isLoggedIn.value = localStorage.getItem('ApiKey');
}
</script>
