<template>
    <div class="fixed-center halvar-font">
        <h3 class="text-center">profiforms API</h3>
        <q-card square bordered class="q-pa-lg shadow-1">
            <q-card-section>
            <q-form class="q-gutter-md">
                <q-input for="apiKey" square filled clearable v-model="apiKey" type="password" label="API-Schüssel" />
            </q-form>
            </q-card-section>
            <q-card-actions class="q-px-md">
            <q-btn id="loginButton" @click="login" unelevated color="primary" size="lg" class="full-width" label="Anmelden" />
            </q-card-actions>
        </q-card>
        <h5 v-if="badCredentials" class="text-center text-red">Ungültige Anmeldeinformationen</h5>
    </div>
</template>



<script>
export default {
    data() {
        return {
            badCredentials: false,
            apiKey: '',
        };
    },
    methods: {
        login() {
            const pattern = /^[a-zA-Z0-9]{40}$/;
            if (!pattern.test(this.apiKey)) {
                this.badCredentials = true;
                return;
            }

            localStorage.setItem('ApiKey', this.apiKey);
            this.$emit('LoginSuccess');
        },
    },
};
</script>

<style scoped>
.container {
    position: absolute;
    top: 10%;
    width: 50%;
}
</style>
