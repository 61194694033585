function buildApiURL(resource) {
    const protocol = window.location.protocol;
    const currentHost = window.location.host;
    const apiHost = currentHost.replace('docs.', '');
    return `${protocol}//${apiHost}${resource}`;
}

export {
    buildApiURL,
}
