<template>
    <div class="container">
        <q-list bordered>
            <q-item
                v-for="file in files"
                :key="file.id"
                clickable
                :href="file.href"
                download
            >
                <q-item-section avatar>
                    <q-icon name="download" />
                </q-item-section>
                <q-item-section>
                    <q-item-label>{{ file.name }}</q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </div>
</template>

<script>
export default {
    data() {
        return {
            files: [
                {
                    name: 'valid-xrechnung-without-attachment.xml',
                    href: '/sample_files/valid-xrechnung-without-attachment.xml',
                },
                {
                    name: 'valid-xrechnung-with-attachment.xml',
                    href: '/sample_files/valid-xrechnung-with-attachment.xml',
                },
                {
                    name: 'invalid-xrechnung.xml',
                    href: '/sample_files/invalid-xrechnung.xml',
                },
                {
                    name: 'scanned-vertragsbestätigung.pdf',
                    href: '/sample_files/scanned-vertragsbestätigung.pdf',
                },
                {
                    name: 'vertragsbestätigung.pdf',
                    href: '/sample_files/vertragsbestätigung.pdf',
                },
                {
                    name: 'zugferd.pdf',
                    href: '/sample_files/zugferd.pdf',
                }
            ],
        };
    },
};
</script>

<style scoped>
.container {
    position: absolute;
    top: 10%;
    width: 50%;
}
</style>
