<template>
    <q-layout>
        <q-header elevated class="bg-pf-primary no-wrap row">
            <q-toolbar>
                <q-btn
                    flat
                    dense
                    round
                    @click="toggleLeftDrawer"
                    aria-label="Menu"
                    icon="menu"
                />
                <q-toolbar-title> profiforms API </q-toolbar-title>
            </q-toolbar>
            <q-toolbar class="col-3 bg-pf-primary">
                 <q-img      src="https://www.profiforms.de/hubfs/profifroms-logo-negativ-menubar.svg"
      spinner-color="white"
      style="height: 50px; margin: auto"
      fit="fill"
    />
      </q-toolbar>
        </q-header>

        <q-drawer v-model="leftDrawerOpen" show-if-above class="bg-pf-primary">
            <q-list dark>
                <q-item-label header>Demo-Apps</q-item-label>
                <q-item
                    id="xrechnung-visualisierung-link"
                    :to="'/xrechnung-visualisierung'"
                    active-class="active-link"
                >
                    <q-item-section avatar>
                        <q-icon name="visibility" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>XRechnung Visualisierung</q-item-label>
                    </q-item-section>
                </q-item><q-item
                    id="xrechnung-validierung-link"
                    :to="'/xrechnung-validierung'"
                    active-class="active-link"
                >
                    <q-item-section avatar>
                        <q-icon name="check_circle_outline" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>XRechnung Validierung</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    id="zugferd-validation-link"
                    :to="'/zugferd-validation'"
                    active-class="active-link"
                >
                    <q-item-section avatar>
                        <q-icon name="verified" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>ZUGFeRD Validierung</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item id="ocr-link" :to="'/ocr'" active-class="active-link">
                    <q-item-section avatar>
                        <q-icon name="document_scanner" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>PDF OCR</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item id="pdfxua-link" :to="'/pdfxua'" active-class="active-link">
                    <q-item-section avatar>
                        <q-icon name="hearing" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>PDF zu PDF/UA</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    id="sample-files-link"
                    :to="'/sample-files'"
                    active-class="active-link"
                >
                    <q-item-section avatar>
                        <q-icon name="folder" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>Beispieldateien</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item-label header>API-Referenz</q-item-label>
                <q-item
                    id="swagger-ui-link"
                    :to="'/swagger'"
                    active-class="active-link"
                >
                    <q-item-section avatar>
                        <q-icon name="school" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>Swagger UI</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    id="swagger-ui-json"
                    target="_blank"
                    rel="noopener"
                    href="/oas30.json"
                >
                    <q-item-section avatar>
                        <q-icon name="code" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>OpenAPI-Spezifikation</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item-label header>Links</q-item-label>
                <q-item
                    clickable
                    target="_blank"
                    rel="noopener"
                    href="https://profiforms.de"
                >
                    <q-item-section>
                        <q-item-label>Profiforms-Website</q-item-label>
                        <q-item-label caption
                            >https://profiforms.de</q-item-label
                        >
                    </q-item-section>
                </q-item>
            </q-list>
            <q-btn v-if="!apiKey" id="logoutButton" @click="logout" class="text-white bg-secondary q-ma-md absolute-bottom" label="Abmelden"/>
        </q-drawer>

        <q-page-container>
            <q-page class="flex flex-center">
                <router-view :key="$route.fullPath" />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
import { ref } from 'vue';
import { buildApiURL } from '../shared';

export default {
    props: {
        apiKey: String,
    },
    setup() {
        const leftDrawerOpen = ref(false);
        function toggleLeftDrawer() {
            leftDrawerOpen.value = !leftDrawerOpen.value;
        }
        return {
            leftDrawerOpen,
            toggleLeftDrawer,
        };
    },
    methods: {
        logout() {
            localStorage.removeItem('ApiKey');
            this.$emit('LogoutSuccess');
        },
        buildApiURL,
    },
};
</script>
