<template>
    <div class="column absolute-full">
        <h4 :id="pageID+'-page-header'" class="col-shrink q-ma-md q-mt-xl text-center">
            {{ name }}
        </h4>
        <q-file
            v-if="!loading"
            for="fileUploadInput"
            class="col-shrink q-ma-md"
            :label="fileInputLabel"
            filled
            v-model="file"
            @update:model-value="uploadFile"
        />
        <q-spinner v-if="loading" class="col-shrink q-ma-md" size="80px" />
        <q-banner
            v-if="showErrorMessage"
            inline-actions
            class="col-shrink q-ma-md text-while bg-red-2 basic"
        >
            <div>{{ errorMessage }}</div>
        </q-banner>
        <embed
            v-if="resultPdfURL"
            :id="pageID + '-response'"
            class="col-grow"
            :src="resultPdfURL"
            type="application/pdf"
        />
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
    props: {
        name: String,
        fileInputLabel: String,
        url: String,
        pageID: String,
        resultingContentType: String,
    },
    setup(props) {
        const loading = ref(false);
        const file = ref(null);
        const showErrorMessage = ref(false);
        const errorMessage = ref('');
        const resultPdfURL = ref('');

        function uploadFile(file) {
            errorMessage.value = '';
            showErrorMessage.value = false;

            console.log('upload file with size:', file.size);
            loading.value = true;

            axios
                .post(props.url, file, {
                    headers: {
                        accept: 'application/pdf',
                        'x-api-key': localStorage.getItem('ApiKey'),
                        'Content-Type': 'application/xml',
                    },
                    responseType: 'blob',
                })
                .then((response) => {
                    const pdfUrl = URL.createObjectURL(
                        new Blob([response.data], { type: props.resultingContentType })
                    );
                    resultPdfURL.value = pdfUrl;
                })
                .catch((error) => {
                    if (!error.response) {
                        console.error('Error: ', error);
                        errorMessage.value = error.message;
                        showErrorMessage.value = true;
                        return;
                    }
                    console.error('Error: ', error);
                    error.response.data
                        .text()
                        .then((text) => {
                            console.error('Error response body:', text);
                            errorMessage.value = text;
                            showErrorMessage.value = true;
                        })
                        .catch((textError) => {
                            console.error(
                                'Error reading error response:',
                                textError
                            );
                            errorMessage.value = 'Error processing file';
                            showErrorMessage.value = true;
                        });
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        return {
            loading,
            file,
            errorMessage,
            showErrorMessage,
            uploadFile,
            resultPdfURL,
        };
    },
};
</script>
